

































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import { Business, Role } from "@/types";

import serviceStoreModule from "@/store/modules/service";
import serviceCategoryModule from "@/store/modules/serviceCategory";

const { mapActions: serviceActions, mapGetters: serviceGetters } =
  createNamespacedHelpers("SERVICE_");
const {
  mapActions: serviceCategoryActions,
  mapGetters: serviceCategoryGetters,
} = createNamespacedHelpers("SERVICE_CATEGORY_");

export default Vue.extend({
  name: "ServiceDepositForm",
  data: () => ({
    valid: false,
    status: "",
    servicesApplied: [] as string[],
    serviceCategoriesApplied: [] as string[],
    applyTo: "all",
    valueType: "percentage",
    amount: 0,
    allServices: false,
    allCategories: false,
  }),
  computed: {
    ...serviceCategoryGetters(["serviceCategoryPage"]),
    ...serviceGetters(["servicePage"]),
    role(): Role {
      return this.$store.getters.role;
    },
    getRules: () => (field: string) =>
      [(v: string) => !!v || `${field} field is required`],
  },
  created() {
    this.loadData();
  },
  methods: {
    ...serviceActions(["fetchServiceList", "bulkUpdateServices"]),
    ...serviceCategoryActions(["fetchServiceCategoryList"]),
    validateForm() {
      (
        this.$refs.serviceDepositForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!this.valid) return;

      const payload = {
        data: this.servicesApplied.map((service) => ({
          serviceId: service,
          update: {
            deposit: {
              amount: this.amount,
              amountType: this.valueType,
            },
            requiresDeposit: true,
          },
        })),
      };

      this.bulkUpdateServices(payload).then((saved) => {
        if (saved) {
          this.$emit("data-saved", saved);
        }
      });
    },
    loadData() {
      if (this.role) {
        const bid = (this.role.business as Business)?._id;
        const params = `?businessId=${bid}&limit=10000`;
        this.fetchServiceCategoryList(params);
      }
    },
    depositRule() {
      return [(v: number) => v > 0 || `Deposit value must be greater than 0`];
    },
    loadServices(all: boolean) {
      const ids = this.serviceCategoriesApplied;
      if (!all && !ids.length) return;
      let params = `?categoryIds=${ids.join(",")}&limit=10000`;
      if (all) {
        const bid = (this.role.business as Business)?._id;
        params = `?businessId=${bid}&limit=10000`;
      }
      this.fetchServiceList(params).then((page) => {
        if (page)
          this.servicesApplied = page.docs.map((service) => service._id);
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("SERVICE_")) {
      this.$store.registerModule("SERVICE_", serviceStoreModule);
    }

    if (!this.$store.hasModule("SERVICE_CATEGORY_")) {
      this.$store.registerModule("SERVICE_CATEGORY_", serviceCategoryModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("SERVICE_");
    this.$store.unregisterModule("SERVICE_CATEGORY_");
  },
});
